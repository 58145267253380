export async function loadConfig() {
  const response = await fetch('/config.json'); // Charger depuis `public/`
  return response.json();
}

export async function loadTranslations() {
  const response = await fetch('/i18n/login_fr.json'); // Charger depuis `public/i18n/`
  return response.json();
}

