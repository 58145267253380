import { createRouter, createWebHistory } from "vue-router";
import { LoginCallback } from "@okta/okta-vue";
import { navigationGuard } from "@okta/okta-vue";

// COMPONENTS AND VIEWS
import Home from "../views/HomeView.vue";
import LoginComponent from "@/components/Login.vue";
import UserManagement from "@/views/UserManagement.vue";
import CloneUser from "@/views/CloneUser.vue";
import UserCreate from "../views/UserCreate.vue";
//import AppsManagement from "@/views/AppsManagement.vue";

//ROUTES DEFINITIONS
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/login/callback",
    component: LoginCallback,
  },
  {
    path: "/userManagement",
    name: "UserManagement",
    component: UserManagement,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userCreate",
    name: "UserCreate",
    component: UserCreate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloneUser",
    name: "CloneUser",
    component: CloneUser,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//OKTA-VUE ROUTER PROTECTION CHECKS IF AUTHENTICATED REQUIRED
router.beforeEach(navigationGuard);

export default router;
