<template>
  <div class="overView">
    <div name="shopsAndUsers">
      <div name="createNewUser" class="d-flex justify-content-end w/100">
        <a class="btn btn-outline-primary" href="userCreate">Ajouter un utilisateur</a>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-4">
        <h2>Mes utilisateurs :</h2>
       <div class="w-25 position-relative">
  <div class="input-group">
    <!-- Champ de recherche avec bordure arrondie et icône -->
    <input 
      type="search" 
      class="form-control rounded-pill ps-4 pe-5" 
      v-model="searchText" 
      placeholder="Chercher un nom ou prénom..." 
      @input="search()"
    >
    <!-- Icône pour effacer la recherche -->
    <span 
      v-if="searchText" 
      class="position-absolute end-0 top-50 translate-middle-y pe-3 text-muted" 
      style="cursor: pointer;" 
      @click="clearSearch"
    >
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
</div>

      </div>
      <div v-if="this.searchResults != ''" class="d-flex flex-column bg-white rounded shadow mb-2">
        <p class="mt-3 ps-1 h4">Utilisateurs trouvés :</p>
        <ul class="list-unstyled justify-content-start">
          <li v-for="user in searchResults.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))"
            :key="user.id"><a :href="`userManagement?id=${user.id}`"
              class="btn text-start text-primary w-100 border-0"><strong>{{ user.profile.firstName }} {{
                user.profile.lastName }}</strong></a></li>
        </ul>
      </div>
      <div v-if="filteredResults.length > 0">
        <button class="btn btn-outline-danger" role="button" @click="resetFilter">Enlever le filtre</button>
      </div>
      <div v-if="filteredResults.length == 0 && this.filterActive === true">
        <button class="btn btn-outline-danger mb-2" role="button" @click="resetFilter">Enlever le filtre</button>
        
        <p>Le filtre actif ne renvoie aucun résultat : {{ this.filterDescription }}</p>
        <hr />
      </div>
      <table v-if="filteredResults.length == 0 && this.filterActive === false" class="table"> <!--Table for standard results-->
        <tbody>
          <tr class="grid-rules w-100">
            <td class="text-primary border-0"><strong>Nom</strong></td>
            <td class="text-primary border-0"><strong>Adresse email</strong></td>
            <td class="text-primary border-0">
              <strong>Statut</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="ACTIVE"
                    @click="filterStatus($event.target.getAttribute('value'))">Actif</a></li>
                <li><a class="dropdown-item" role="button" value="DEPROVISIONED"
                    @click="filterStatus($event.target.getAttribute('value'))">Désactivé</a></li>
                <li><a class="dropdown-item" role="button" value="PROVISIONED"
                    @click="filterStatus($event.target.getAttribute('value'))">En attente d'activation</a></li>
                <li><a class="dropdown-item" role="button" value="RECOVERY"
                    @click="filterStatus($event.target.getAttribute('value'))">Mot de passe réinitialisé</a></li>
                <li><a class="dropdown-item" role="button" value="PASSWORD_EXPIRED"
                    @click="filterStatus($event.target.getAttribute('value'))">Mot de passe expiré</a></li>
                <li><a class="dropdown-item" role="button" value="LOCKED_OUT"
                    @click="filterStatus($event.target.getAttribute('value'))">Verrouillé</a></li>
                <li><a class="dropdown-item" role="button" value="SUSPENDED"
                    @click="filterStatus($event.target.getAttribute('value'))">Suspendu</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Rôle</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="Collaborateur"
                    @click="filterRole($event.target.getAttribute('value'))">Collaborateur</a></li>
                <li><a class="dropdown-item" role="button" value="Responsable"
                    @click="filterRole($event.target.getAttribute('value'))">Directeur(trice) Magasin(s)</a></li>
                <li><a class="dropdown-item" role="button" value="Associé(e)"
                    @click="filterRole($event.target.getAttribute('value'))">Associé(e) ou Partenaire</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Magasin(s)</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li v-for="group in groups" :key="group.id"><a class="dropdown-item" role="button"
                    :value="group.profile.name"
                    @click="filterStore(group.profile.name)">{{ (group.profile.name).substring(13) }}</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Dernière activité</strong> <button class="btn btn-outline-primary dropdown-toggle h-75"
                type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="Aucune"
                    @click="filterLastLogin($event.target.getAttribute('value'))">Aucune</a></li>
                <li><a class="dropdown-item" role="button" value="+ de 60 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">+ de 60 jours</a></li>
                <li><a class="dropdown-item" role="button" value="+ de 30 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">+ de 30 jours</a></li>
                <li><a class="dropdown-item" role="button" value="- de 30 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">- de 30 jours</a></li>
              </ul>
            </td>
          </tr>
          <tr class=" bg-white border-bottom border-primary mb-1 w-100 shadow"
            v-for="user in users.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))" :key="user.id">
            <div class="grid-rules align-items-center">
              <td class="justify-content-center border rounded border-primary align-items-center">
                <a :href="`userManagement?id=${user.id}`" class="btn text-center w-100 border-0">
                  <strong>{{ user.profile.firstName }} {{ user.profile.lastName }}</strong>
                </a>
              </td>
              <td v-if="user.profile.email.length < 24" class="align-items-center ps-1">
                {{ user.profile.email }}
              </td>
              <td v-else class="align-items-center ps-1 overflow-ellipsis">
                <div class="first-line">{{ user.profile.email.split('@')[0] }}</div>
                <div class="second-line">{{ '@' + user.profile.email.split('@')[1] }}</div>
              </td>
              <td class="align-items-center">
                <span class="ps-1" @mouseover="statusOn = true" @mouseleave="statusOn = false">
                  <font-awesome-icon class="ms-1 fa-lg fa-fw" :icon="getUserStatusIcon(user.status)"
                    :color="getStatusIconColor(user.status)" />
                  <small class="ms-2" v-if="statusOn">{{ getUserStatus(user.status) }}</small>
                </span>
              </td>
              <td class="align-items-center">
                <span @mouseover="labelOn = true" @mouseleave="labelOn = false">
                  <font-awesome-icon class="ms-1 ps-1 fa-lg fa-fw" :icon="getUserRoleIcon(user.profile)" color="gray" />
                  <small v-if="labelOn" class="ms-2 w-75">{{ getUserRole(user.profile.refPortalRole) }}</small>
                </span>
              </td>
              <td class="align-items-center ms-2 ps-2">
                {{ user.profile.magasinCode }}
              </td>
              <td class="align-items-center ms-2 ps-2" :style="{ color: getLoginColor(user.lastLogin) }">
                {{ formatDate(user.lastLogin) }}
              </td>
            </div>
          </tr>
        </tbody>
      </table>
      <table v-if="this.filteredResults.length != 0" class="table"> <!--Table for filteredResults-->
        <tbody>
          <tr class="grid-rules w-100">
            <td class="text-primary border-0"><strong>Nom</strong></td>
            <td class="text-primary border-0"><strong>Adresse email</strong></td>
            <td class="text-primary border-0">
              <strong>Statut</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="ACTIVE"
                    @click="filterStatus($event.target.getAttribute('value'))">Actif</a></li>
                <li><a class="dropdown-item" role="button" value="DEPROVISIONED"
                    @click="filterStatus($event.target.getAttribute('value'))">Désactivé</a></li>
                <li><a class="dropdown-item" role="button" value="PROVISIONED"
                    @click="filterStatus($event.target.getAttribute('value'))">En attente d'activation</a></li>
                <li><a class="dropdown-item" role="button" value="RECOVERY"
                    @click="filterStatus($event.target.getAttribute('value'))">Mot de passe réinitialisé</a></li>
                <li><a class="dropdown-item" role="button" value="PASSWORD_EXPIRED"
                    @click="filterStatus($event.target.getAttribute('value'))">Mot de passe expiré</a></li>
                <li><a class="dropdown-item" role="button" value="LOCKED_OUT"
                    @click="filterStatus($event.target.getAttribute('value'))">Verrouillé</a></li>
                <li><a class="dropdown-item" role="button" value="SUSPENDED"
                    @click="filterStatus($event.target.getAttribute('value'))">Suspendu</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Rôle</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="Collaborateur"
                    @click="filterRole($event.target.getAttribute('value'))">Collaborateur</a></li>
                <li><a class="dropdown-item" role="button" value="Responsable"
                    @click="filterRole($event.target.getAttribute('value'))">Directeur(trice) Magasin(s)</a></li>
                <li><a class="dropdown-item" role="button" value="Associé(e)"
                    @click="filterRole($event.target.getAttribute('value'))">Associé(e) ou Partenaire</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Magasin(s)</strong> <button class="btn btn-outline-primary dropdown-toggle h-75" type="button"
                data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li v-for="group in groups" :key="group.id"><a class="dropdown-item" role="button"
                    :value="group.profile.name"
                    @click="filterStore(group.profile.name)">{{ (group.profile.name).substring(13) }}</a></li>
              </ul>
            </td>
            <td class="text-primary border-0">
              <strong>Dernière activité</strong> <button class="btn btn-outline-primary dropdown-toggle h-75"
                type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" role="button" value="Aucune"
                    @click="filterLastLogin($event.target.getAttribute('value'))">Aucune</a></li>
                <li><a class="dropdown-item" role="button" value="+ de 60 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">+ de 60 jours</a></li>
                <li><a class="dropdown-item" role="button" value="+ de 30 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">+ de 30 jours</a></li>
                <li><a class="dropdown-item" role="button" value="- de 30 jours"
                    @click="filterLastLogin($event.target.getAttribute('value'))">- de 30 jours</a></li>
              </ul>
            </td>
          </tr>
          <tr class=" bg-white border-bottom border-primary mb-1 w-100 shadow"
            v-for="user in filteredResults.sort((a, b) => a.profile.lastName.localeCompare(b.profile.lastName))"
            :key="user.id">
            <div class="grid-rules align-items-center">
              <td class="justify-content-center border rounded border-primary align-items-center">
                <a :href="`userManagement?id=${user.id}`" class="btn text-center w-100 border-0">
                  <strong>{{ user.profile.firstName }} {{ user.profile.lastName }}</strong>
                </a>
              </td>
              <td v-if="user.profile.email.length < 24" class="align-items-center ps-1">
                {{ user.profile.email }}
              </td>
              <td v-else class="align-items-center ps-1 overflow-ellipsis">
                <div class="first-line">{{ user.profile.email.split('@')[0] }}</div>
                <div class="second-line">{{ '@' + user.profile.email.split('@')[1] }}</div>
              </td>
              <td class="align-items-center">
                <span class="ps-1" @mouseover="statusOn = true" @mouseleave="statusOn = false">
                  <font-awesome-icon class="ms-1 fa-lg fa-fw" :icon="getUserStatusIcon(user.status)"
                    :color="getStatusIconColor(user.status)" />
                  <small class="ms-2" v-if="statusOn">{{ getUserStatus(user.status) }}</small>
                </span>
              </td>
              <td class="align-items-center">
                <span @mouseover="labelOn = true" @mouseleave="labelOn = false">
                  <font-awesome-icon class="ms-1 ps-1 fa-lg fa-fw" :icon="getUserRoleIcon(user.profile)" color="gray" />
                  <small v-if="labelOn" class="ms-2 w-75">{{ getUserRole(user.profile.refPortalRole) }}</small>
                </span>
              </td>
              <td class="align-items-center ms-2 ps-2">
                {{ user.profile.magasinCode }}
              </td>
              <td class="align-items-center ms-2 ps-2" :style="{ color: getLoginColor(user.lastLogin) }">
                {{ formatDate(user.lastLogin) }}
              </td>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OverView",
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      accessToken: localStorage.getItem('accessToken'),
      users: [],
      user: {},
      groups: [],
      message: {},
      error: {},
      searchText: '',
      searchResults: [],
      filteredResults: [],
      labelOn: false,
      statusOn: false,
      filterActive: false,
      filterDescription: ''
    };
  },
  async created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$data.accessToken}`;

    try {
      // Get users from current user perimter
      const response = await axios.get(`${this.$data.apiUrl}/users`);
      this.users = response.data;
    } catch (e) {
      console.log(e);
    }

    try {
      // Get groups from current user perimeter
      const response = await axios.get(`${this.$data.apiUrl}/groups`);
      this.groups = response.data;
    } catch (e) {
      console.log(e);
    }

  },
  methods: {
    getUserStatus(statusIn) {
      var myNames = {
        ACTIVE: "Actif",
        PROVISIONED: "En attente d'activation",
        RECOVERY: "Mot de passe réinitialisé",
        DEPROVISIONED: "Désactivé",
        STAGED: "Provisionné",
        LOCKED_OUT: "Verrouillé",
        SUSPENDED: "Suspendu",
        PASSWORD_EXPIRED: "Mot de passe expiré",
        PASSWORD_RESET: "Mot de passe réinitialisé"
      };

      var nameOut = myNames[statusIn] ? myNames[statusIn] : statusIn;
      return nameOut;
    },
    getUserStatusIcon(statusIn) {
      var myIcons = {
        ACTIVE: "fa-solid fa-toggle-on",
        DEPROVISIONED: "fa-solid fa-toggle-off",
        PROVISIONED: "fa-solid fa-unlock-keyhole",
        RECOVERY: "fa-solid fa-lock",
        PASSWORD_EXPIRED: "fa-solid fa-lock",
        LOCKED_OUT: "fa-solid fa-lock",
        STAGED: "fa-solid fa-hourglass-half"
      };

      var statusOut = myIcons[statusIn] ? myIcons[statusIn] : "fa-solid fa-question";
      return statusOut;
    },
    getStatusIconColor(statusIn) {
      var myIcons = {
        ACTIVE: "green",
        DEPROVISIONED: "red",
        PROVISIONED: "blue",
        RECOVERY: "black",
        PASSWORD_EXPIRED: "blue",
        LOCKED_OUT: "black",
        STAGED: "blue"
      };

      var statusOut = myIcons[statusIn] ? myIcons[statusIn] : "fa-solid fa-question";
      return statusOut;
    },
    getLoginColor(loginDate) {
      var today = new Date();
      var lastLogin = new Date(loginDate);

      const differenceInMilliseconds = today - lastLogin;

      const millisecondsIn60Days = 60 * 24 * 60 * 60 * 1000;
      const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;

      var color = "";

      if (loginDate == null) {
        color = 'red';
      } else {
        if (differenceInMilliseconds > millisecondsIn60Days) {
          color = "#D46D28";
        }


        if (differenceInMilliseconds > millisecondsIn30Days && differenceInMilliseconds < millisecondsIn60Days) {
          color = "#E3CA28";
        }

        if (differenceInMilliseconds < millisecondsIn30Days) {
          color = "green";
        }
      }     

      return color;
    },
    getUserRole(roleIn) {
      var roleOut = roleIn == "Associé(e)" ? "Associé(e) ou Partenaire" : roleIn == "Responsable" ? "Directeur(trice) Magasin(s)" : roleIn;
      return roleOut;
    },
    getUserRoleIcon(statusIn) {
      var statusOut = statusIn.refPortalRole == "Responsable" ? "fa-solid fa-user-tie" : statusIn.refPortalRole == "Associé(e)" ? "fa-solid fa-users-gear" : "fa-solid fa-user-alt";
      return statusOut;
    },
    async search() {
      if (this.searchText.length < 3) {
        this.searchResults = [];
        return;
      }
      this.searchResults = this.users.filter(user => Object.values(user.profile).some(item => typeof item === 'string' && item.includes(this.searchText)));
    },
    async clearSearch() {
      this.searchText = '';
      this.search();
    },
    async filterStatus(value) {
      if (this.filteredResults.length) {
        this.filteredResults = [];
      }
      this.filteredResults = this.users.filter(user => user.status == value);
      this.filterActive = true;
      var statuses = {
        ACTIVE: "Actif",
        PROVISIONED: "En attente d'activation",
        RECOVERY: "Mot de passe réinitialisé",
        DEPROVISIONED: "Désactivé",
        STAGED: "Provisionné",
        LOCKED_OUT: "Verrouillé",
        SUSPENDED: "Suspendu",
        PASSWORD_EXPIRED: "Mot de passe expiré",
        PASSWORD_RESET: "Mot de passe réinitialisé"
      };

      var status = statuses[value];

      console.log(status)
      this.filterDescription = `Utilisateurs avec le statut "${status}"`
    },
    async filterRole(value) {
      if (this.filteredResults.length) {
        this.filteredResults = [];
      }
      this.filteredResults = this.users.filter(user => user.profile.refPortalRole == value);
      this.filterActive = true;

      var role = value == "Associé(e)" ? "Associé(e) ou Partenaire" : value == "Responsable" ? "Directeur(trice) Magasin(s)" :

      this.filterDescription = `Utilisateurs avec le rôle "${role}"`
    },
    async filterStore(value) {
      if (this.filteredResults.length) {
        this.filteredResults = [];
      }
      this.filteredResults = this.users.filter(user => Object.values(user.profile).some(item => typeof item === 'string' && item.includes(value.substring(13))));
      this.filterActive = true;
    },
    async filterLastLogin(value) {

      if (this.filteredResults.length) {
        this.filteredResults = [];
      }

      var today = new Date();

      const millisecondsIn60Days = 60 * 24 * 60 * 60 * 1000;
      const millisecondsIn30Days = 30 * 24 * 60 * 60 * 1000;

      switch (value) {
        case 'Aucune':
          this.filteredResults = this.users.filter(user => user.lastLogin == null)
          break;
        
        case '+ de 60 jours':
          this.users.forEach(user => {
            var lastLoginDate = new Date(user.lastLogin);
            var differenceInMilliseconds = today - lastLoginDate;
            if (differenceInMilliseconds > millisecondsIn60Days) {
              if (user.lastLogin == null) {
                return
              }
              this.filteredResults.push(user);
              this.filterDescription = 'Utilisateurs avec une dernière activité datant de plus de 60 jours'
            }
          })
          break;

        case '+ de 30 jours':
        this.filterDescription = 'Utilisateurs avec une dernière activité datant de plus de 30 jours'
        this.users.forEach(user => {
            var lastLoginDate = new Date(user.lastLogin);
            var differenceInMilliseconds = today - lastLoginDate;
            if (differenceInMilliseconds > millisecondsIn30Days) {
              if (differenceInMilliseconds < millisecondsIn60Days) {
                this.filteredResults.push(user);          
              } 
            }
          })
          break;

        case '- de 30 jours':
        this.users.forEach(user => {
            var lastLoginDate = new Date(user.lastLogin);
            var differenceInMilliseconds = today - lastLoginDate;
            if (differenceInMilliseconds < millisecondsIn30Days) {
              if (user.lastLogin == null) {
                return
              }
              this.filteredResults.push(user);
              this.filterDescription = 'Utilisateurs avec une dernière activité datant de moins de 30 jours'
            }
          })
          break;

        default:
          return this.filteredResults;
      }
      this.filterActive = true;
    },
    async resetFilter() {
      this.filteredResults = [];
      this.filterActive = false;
    },
    formatDate(value) {
      var date = new Date(value);
      var formatedDate = date.toLocaleString();
      return formatedDate != '01/01/1970 01:00:00' ? formatedDate.substring(0, 10) : 'Aucune'
    },
  },
};
</script>
<style>
  .grid-rules {
    display: grid;
    gap: 20px;
    grid-template-columns: 18% 16% 15% 17% 11% 21%;
  }
  .overflow-ellipsis {
    word-break: break-all
  } 
  
</style>
