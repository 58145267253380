<template>
  <AlterNavBar v-if="authState && !authState.isAuthenticated" />
  <NavBar class="w-75 mx-auto" v-if="authState && authState.isAuthenticated" />
  <div class="home w-75 mx-auto">
    <Login v-if="authState && !authState.isAuthenticated" />
    <Overview v-if="authState && authState.isAuthenticated" />
    <!-- <h1 class="mb-5"> <img style="height: 4em" alt="Audioptic logo" src="../assets/audioptic_logo.jpeg"/> L'Annuaire est en cours de maintenance</h1>
  
    <div class="d-flex flex-column mt-5 justify-content-center align-items-center">
      <p class="h2 mb-2">Pour des raisons de maintenance, l'application Annuaire est indisponible.</p>

      <p class="h2 mb-2">Nous vous prions de nous excuser pour la gêne occasionnée.</p>  
      
   
      <p class="h2">Le Service Informatique</p>
    </div> -->
    
  
  </div>
</template>

<script>
import AlterNavBar from "../components/AlterNavBar.vue";
import NavBar from "../components/NavBar.vue";
import Login from "../components/Login.vue";
import Overview from "../components/Overview.vue";

export default {
  name: "HomeView",

  components: {
    Login,
    Overview,
    AlterNavBar,
    NavBar,
  },
};
</script>
