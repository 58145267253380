<template>
  <NavBar class="w-75 mx-auto" />
  <div class="userManagement w-75 mx-auto">
    <div class="d-flex flex-column">
      <a class="d-flex justify-content-start text-decoration-none" href="/">Retour à la liste des utilisateurs</a>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <h5 style="margin-top: 3rem; text-align: left">Profil utilisateur <a class="btn border-0"
            :href="`cloneUser?fromUserId=${id}`" @mouseover="cloneOn = true"
            @mouseleave="cloneOn = false"><font-awesome-icon icon="fa-solid fa-copy" color="blue" /><small class="ms-2"
              v-if="cloneOn">Créer un clone</small></a></h5>
        <div v-if="this.phoneNumberError || this.emailError || this.firstNameError || this.lastNameError">
          <h6 class="text-danger">Erreurs :</h6>
          <p v-if="this.phoneNumberError">{{ this.phoneNumberError }}</p>
          <p v-if="this.emailError">{{ this.emailError }}</p>
          <p v-if="this.lastNameError">{{ this.lastNameError }}</p>
          <p v-if="this.firstNameError">{{ this.firstNameError }}</p>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <td class="text-start">
                <strong>Statut:</strong>
              </td>
              <td class="text-start">{{ getUserStatus(status) }}</td>
            </tr>
            <tr>
              <td class="text-start">
                <strong>Date de dernière connexion</strong>
              </td>
              <td class="text-start">{{ formatDate(this.lastLogin) }}</td>
            </tr>
          </thead>
          <tbody v-for="(item, label) in profile" :key="label" v-bind:value="profile.item">
            <!-- <tr v-if="label != 'fonction' && label != 'magasinCode' && label != 'secondEmail' && label != 'logicielEnseigne'"> -->
              <tr v-if="config.tabAttributes.includes(label)">
              <td class="text-start "> <strong>{{ getUserAttributeNames(label) }} </strong></td>
              <td v-if="label == 'endOfContract' && connectedUserId != currentUserId"><input :name="label" type="date"
                  :id="label" :value="item" @input="item = $event.target.value" :placeholder="item" /></td>
              <td v-else-if="label == 'endOfContract' && connectedUserId === currentUserId"><input :name="label"
                  :id="label" :value="item" disabled /></td>
              <td v-if="label != 'login' && label != 'refPortalRole' && label != 'endOfContract' && label != 'enseigne' && label != 'email'">
                <input :name="label" :class="label" :id="label" type="text" :value="item"
                  @input="item = $event.target.value" style="width: 100%;" />
              </td>
              <td class="text-start" v-if="label == 'login'">{{ item }}</td>
              <td class="text-start" v-if="label == 'refPortalRole'">{{ item }}</td>
              <td class="text-start" v-if="label == 'enseigne'">{{ getBrand(item) }}</td>
              <td class="text-start" v-if="label == 'email'">{{ item }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" class="text-end">
                <div class="form-check text-start mb-2">
                  <input v-if="profile.refPortalRole === 'Associé(e)'" class="form-check-input" type="checkbox"
                    id="isReferent" v-model="isReferent" disabled>
                  <input v-else-if="profile.refPortalRole === 'Responsable' && connectedUserId === currentUserId"
                    class="form-check-input" type="checkbox" id="isReferent" v-model="isReferent" disabled>
                  <input v-else class="form-check-input" type="checkbox" id="isReferent" v-model="isReferent">
                  <label v-if="profile.refPortalRole === 'Associé(e)'" class="form-check-label"
                    for="flexCheckDefault">Associé(e)</label>
                  <label v-else class="form-check-label" for="flexCheckDefault">Directeur(trice) Magasin(s)</label>
                </div>
                <button v-if="status != 'DEPROVISIONED'" class="btn btn-outline-primary mb-2 me-3"
                  @click="resetPassword">Reset password</button>
                <button v-if="status != 'DEPROVISIONED' && profile.refPortalRole != 'Collaborateur'"
                  class="btn btn-outline-primary mb-2 me-3" data-bs-toggle="modal"
                  data-bs-target="#resetFactorSelection">Reset d'un facteur MFA</button>
                <button v-if="status === 'DEPROVISIONED'" type="button" class="btn btn-outline-danger mb-2 me-3"
                  data-bs-toggle="modal" data-bs-target="#reactivate">Réactiver</button>
                <button v-if="status === 'DEPROVISIONED'" type="button" class="btn btn-outline-danger mb-2 me-3"
                  data-bs-toggle="modal" data-bs-target="#delete">Supprimer</button>
                <button
                  v-if="connectedUserId != currentUserId && profile.refPortalRole != 'Associé(e)' && status === 'ACTIVE' || connectedUserId != currentUserId && profile.refPortalRole != 'Associé(e)' && status === 'PROVISIONED' || connectedUserId != currentUserId && profile.refPortalRole != 'Associé(e)' && status === 'RECOVERY' || connectedUserId != currentUserId && profile.refPortalRole != 'Associé(e)' && status === 'PASSWORD_EXPIRED'"
                  class="btn btn-outline-danger mb-2 me-3" data-bs-toggle="modal"
                  data-bs-target="#deactivate">Désactiver</button>
                <button
                  v-if="status != 'DEPROVISIONED' && connectedUserId != currentUserId && profile.refPortalRole != 'Associé(e)'"
                  class="btn btn-outline-primary mb-2" @click="updateUserProfile">Sauvegarder</button>
                <button v-if="connectedUserId === currentUserId && profile.refPortalRole != 'Associé(e)'"
                  class="btn btn-outline-primary mb-2" @click="updateUserProfile">Sauvegarder</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Modal Deactivate -->
      <div class="modal fade" id="deactivate" tabindex="-1" aria-labelledby="deactivate" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Désactivation</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Êtes-vous certain(e) de vouloir désactiver cet utilisateur ?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                @click="deactivateUser">Confirmer</button>
            </div>
          </div>
        </div>
      </div><!-- Modal Delete -->
      <div class="modal fade" id="delete" tabindex="-1" aria-labelledby="delete" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Suppression</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Êtes-vous certain(e) de vouloir supprimer cet utilisateur ?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="deleteUser">Confirmer</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Reactivate -->
      <div class="modal fade" id="reactivate" tabindex="-1" aria-labelledby="reactivate" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Désactivation</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              Êtes-vous certain(e) de vouloir réactiver cet utilisateur ?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                @click="reactivateUser">Confirmer</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal resetFactorSelection -->
      <div class="modal fade" id="resetFactorSelection" tabindex="-1" aria-labelledby="resetFactorSelection"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Choix du facteur à réinitialiser</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <select class="form-select" v-model="selectedFactor">
                <option disabled value="">Choisir un facteur</option>
                <option v-for="enrolledFactor in enrolledFactors" :key="enrolledFactor.id" :value="enrolledFactor.id">{{
                  getFactorsName(enrolledFactor.factorType) }}</option>
              </select>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="resetFactor">Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 ms-3 mt-2">
        <h5 style="margin-top: 3rem">Magasin(s)</h5>
        <div v-for="referentGroup in referentGroups" :key="referentGroup">
          <div class="form-check">
            <input v-if="this.profile.refPortalRole === 'Associé(e)'" disabled class="form-check-input" type="checkbox"
              :value="referentGroup.id" :name="referentGroup.profile.name" :id="referentGroup.id" v-model="checkedStores">
            <input v-else-if="referentGroups.length === 1" disabled class="form-check-input" type="checkbox"
              :value="referentGroup.id" :name="referentGroup.profile.name" :id="referentGroup.id" v-model="checkedStores">
            <input v-else class="form-check-input" type="checkbox" :value="referentGroup.id"
              :name="referentGroup.profile.name" :id="referentGroup.id" v-model="checkedStores">
            <label class="form-check-label" :for="referentGroup.id">{{ (referentGroup.profile.name).substring(13)
            }}</label>
          </div>
        </div>
        <div v-if="status != 'DEPROVISIONED' && this.neitherRoleNorBrand != true">
          <h5 class="mt-4">Applications disponibles</h5>
          <div v-for="app in availableApps" :key="app">
            <div v-if="this.profile.refPortalRole =='Associé(e)'" class="form-check">
              <input disabled class="form-check-input" type="checkbox" :value="app.id" :name="app.label" :id="app.id"
                v-model="checkedApps">
              <label class="form-check-label" :for="app.id"><img style="width: 2em; height: 2em" class="img-thumbnail"
                :src="app._links.logo[0].href" /> {{ app.label }}</label>
            </div>
            <div v-else class="form-check">
              <input class="form-check-input" type="checkbox" :value="app.id" :name="app.label" :id="app.id"
                v-model="checkedApps">
              <label class="form-check-label" :for="app.id"><img style="width: 2em; height: 2em" class="img-thumbnail"
                :src="app._links.logo[0].href" /> {{ app.label }}</label>
            </div>
          </div>
          <h5 class="mt-3">Applications affectées non modifiables</h5>
          <div v-for="app in unmodifiableApps" :key="app">
            <div class="form-check">
              <input disabled class="form-check-input" type="checkbox" :value="app.id" :name="app.label" :id="app.id"
                v-model="checkedApps">
              <label class="form-check-label" :for="app.id"><img style="width: 2em; height: 2em" class="img-thumbnail"
                :src="app._links.logo[0].href" /> {{ app.label }}</label>
            </div>
          </div>
          <div v-if="this.profile.refPortalRole != 'Collaborateur'">
            <h5 class="mt-4">Seconds facteurs d'authentification</h5>
            <ul class="list-unstyled" v-for="factor in factors" :key="factor">
              <li>
                <p :for="factor.id"><font-awesome-icon class="me-2 fa-lg fa-fw" :icon="getFactorsIcon(factor.status)"
                    :color="getFactorsIconColor(factor.status)" /> {{ getFactorsName(factor.factorType) }} </p>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="this.neitherRoleNorBrand == true && status != 'DEPROVISIONED'">
          <h5 class="mt-4">Applications</h5>
          <p>La liste des applications disponibles pour ce collaborateur sera accessible dans moins de 72h.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "../components/NavBar.vue";
import '@vuepic/vue-datepicker/dist/main.css';
// import config from "@/assets/config.json";
window.$ = window.jQuery = require('jquery');

export default {
  name: "userManagement",
  components: {
    NavBar,
  },

  async created() {


    this.stores = JSON.parse(localStorage.getItem('defaultServerIdToken')).stores;
    console.log("JSON.parse(localStorage.getItem('defaultServerIdToken'): ", JSON.parse(localStorage.getItem('defaultServerIdToken')));

    let getUrlId = window.location.search;
    let userId = getUrlId.slice(4);
    this.id = userId


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$data.accessToken}`;

    try {
      // Get specific user all infos
      const response = await axios.get(`${this.$data.apiUrl}/users/${userId}`);
      this.userInfos = response.data;
      this.lastLogin = this.userInfos[0].lastLogin;
      this.status = this.userInfos[0].status;
      this.profile = this.userInfos[0].profile;
      this.groups = this.userInfos[1];

    } catch (e) {
      console.log(e);
    }

    try {
      const response = await axios.get(`${this.$data.apiUrl}/users/${userId}/apps`);
      this.userApps = response.data;
    } catch(e) {
      console.log(e);
    }


    if (this.status != 'DEPROVISIONED') {
      try {
        //If user's status is not deprovisioned, get factors infos
        const response = await axios.get(`${this.$data.apiUrl}/users/${userId}/factors`);
        this.factorsArray = response.data;
        this.enrolledFactors = this.factorsArray[0];
        this.factors = this.factorsArray[1];
      } catch (e) {
        console.log(e)
      }
    }


    try {
      if(this.profile.enseigne === undefined) {
        this.neitherRoleNorBrand = true;       
      } else if(this.profile.enseigne != undefined) {
        const brands = this.profile.enseigne.join(', ');
        const response = await axios.get(`${this.$data.apiUrl}/resourceSets/unmodifiableApps?role=${this.profile.refPortalRole}&brands=${brands}`);
        this.unmodifiableApps = response.data;
        console.log(this.unmodifiableApps)
        const response2 = await axios.get(`${this.$data.apiUrl}/resourceSets/availableApps?role=${this.profile.refPortalRole}&brands=${brands}`);
        this.availableApps = response2.data;
        console.log(this.availableApps)   
          
      }      
    } catch(e) {
      console.log(e)
    }

    try {
      // Get groups available to logged referent/super-referent
      const response = await axios.get(`${this.$data.apiUrl}/groups`)
      this.referentGroups = response.data
    } catch (e) {
      console.log(e)
    }

    this.referentGroups.forEach(referentGroup => {
      if (this.groups.find(group => group.id == referentGroup.id)) {
        this.checkedStores.push(referentGroup.id)
      }
    });


    this.userApps.forEach(userApp => {
      if (this.availableApps.find(item => item.id == userApp.id)) {// if the userApp id is in the app array, it has to be checked
        if (this.checkedApps.includes(userApp.id) == false) { // prevent duplicate values to be added in array
          this.checkedApps.push(userApp.id) // pushed userApp.id in checkedApps array : the userApp is checked on display
        }
      }
    });

    if (this.unmodifiableApps != undefined) {
      this.unmodifiableApps.forEach(app => {
        if(this.checkedApps.includes(app.id)) {
          return;
        }
        this.checkedApps.push(app.id)
      })
    }
    

    if (this.profile.refPortalRole == 'Responsable') { // If user is a store manager, precheck the checkbox
      this.isReferent = true;
    }

    if (this.profile.refPortalRole == 'Associé(e)') { // Same if user is a super referent
      this.isReferent = true;
    }

    if (this.profile.endOfContract === 'aucune date saisie') {
      this.profile.endOfContract = '';
    }

  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      accessToken: localStorage.getItem('accessToken'),
      connectedUserId: JSON.parse(localStorage.getItem('defaultServerIdToken')) ? JSON.parse(localStorage.getItem('defaultServerIdToken')).sub : '',
      currentUserId: window.location.search.slice(4),
      unmodifiableApps: [],
      availableApps: [],
      userInfos: [],
      groups: [],
      referentGroups: [],
      apps: [],
      userApps: [],
      checkedApps: [],
      removedApps: [],
      factors: [],
      selectedFactor: {},
      stores: [],
      checkedStores: [],
      addedStores: [],
      removedStores: [],
      magasinCode: [],
      status: {},
      profile: {},
      message: {},
      error: {},
      isReferent: false,
      cloneOn: false,
      removeOn: false,
      phoneNumberError: '',
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      phoneProblem: false,
      emailProblem: false,
      firstNameProblem: false,
      lastNameProblem: false,
      storeProblem: false,
      lastLogin: '',
      storeError: '',
      enrolledFactors: [],
      neitherRoleNorBrand: false,
      config:this.$champConfig
    };
  },
  methods: {
    async deactivateUser() {
      let userId = this.id
      try {
        const response = await axios.post(`${this.$data.apiUrl}/users/${userId}/deactivate`);
        this.message = response.data;
      } catch (e) {
        this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
      }
      setTimeout(() => {this.$router.push("/")}, 1800);
    },
    async reactivateUser() {
      let userId = this.id;
      try {
        const response = await axios.post(`${this.$data.apiUrl}/users/${userId}/activate`);
        this.message = response.data;
      } catch (e) {
        this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
      }
      setTimeout(() => {this.$router.push("/")}, 1800);
    },
    async deleteUser() {
      let userId = this.id;
      try {
        const response = await axios.post(`${this.$data.apiUrl}/users/${userId}/delete`);
        this.message = response.data;
      } catch (e) {
        this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
      }
      setTimeout(() => {this.$router.push("/")}, 1800);
    },
    async resetPassword() {
      let userId = this.id
      try {
        const response = await axios.post(
          `${this.$data.apiUrl}/users/${userId}/lifecycle/reset_password`);
        this.message = response.data;
      } catch (e) {
        this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
      }
      setTimeout(() => {this.$router.push("/")}, 1800);
    },
    async resetFactor() {
      let userId = this.id;
      let factorId = this.selectedFactor;
           if (!factorId || typeof factorId !== 'string' || !/^[a-zA-Z0-9]{20,}$/.test(factorId)) {
        this.error = "Erreur : factorId est invalide ou manquant. Vérifiez votre saisie.";
        console.error("Erreur : factorId est invalide ou manquant.");
        // alert("Erreur : factorId est invalide ou manquant. Vérifiez votre saisie.");
        return; // Ne poursuit pas l'exécution
    }

      try {
        const response = axios.post(`${this.$data.apiUrl}/users/${userId}/factors/${factorId}`);
        this.message = response.data;
      } catch (e) {
        this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
      }
    },
    async updateUserProfile() {

      var userId = this.id;
      const phoneRegex = /^(?:(?:(?:\+|00)33\D?(?:\D?\(0\)\D?)?)|0){1}[1-9]{1}(?:\D?\d{2}){4}$/gm;
      const namesRegex = /^(?=.{2,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/gm;

      if (document.querySelector('#mobilePhone').value != '') { // Regex validation of phone number
        if (!document.querySelector('#mobilePhone').value.match(phoneRegex)) {
          this.phoneNumberError = `Numéro de téléphone invalide`;
          this.phoneProblem = true;
        } else {
          this.phoneProblem = false
        }
      }

      if (document.querySelector('#firstName').value != '') { // Regex validation of firstName
        if (!document.querySelector('#firstName').value.match(namesRegex)) {
          this.firstNameError = `Prénom invalide`;
          this.firstNameProblem = true;
        } else {
          this.firstNameProblem = false
        }
      }

      if (document.querySelector('#lastName').value != '') { // Regex validation of lastName
        if (!document.querySelector('#lastName').value.match(namesRegex)) {
          this.lastNameError = `Prénom invalide`;
          this.lastNameProblem = true;
        } else {
          this.lastNameProblem = false
        }
      }

      if(document.querySelector('#firstName').value != '') { // Regex validation of phone number
        if(!document.querySelector('#firstName').value.match(namesRegex)) {
          this.firstNameError = `Prénom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
          this.firstNameProblem = true;
        } else {
          this.firstNameProblem = false
        }
      }

      if(document.querySelector('#lastName').value != '') { // Regex validation of lastName
        if(!document.querySelector('#lastName').value.match(namesRegex)) {
          this.lastNameError = `Nom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
          this.lastNameProblem = true;
        } else {
          this.lastNameProblem = false
        }
      }

      this.availableApps.forEach(app => {
        if (!this.checkedApps.includes(app.id)) {
          if (this.userApps.find(item => item.id == app.id)) {
            this.removedApps.push(app.id) // add apps in list to remove
          }
        }
      });

      var assignedApps = [];
      
      this.checkedApps.forEach(app => {
        if (!this.unmodifiableApps.find(el => el.id === app)) { // Push in assignedApps array apps that are not unmodifiable 
          assignedApps.push(app)
        }
      });

      this.checkedApps.forEach(app => {
        assignedApps.push(app)
      });

      this.groups.forEach(group => {
        if (!this.checkedStores.includes(group.id)) {
          this.removedStores.push(group.id) //remove store if not checked
        }
      });

      if (!this.checkedStores.length) {
        alert("Au moins un magasin doit être sélectionné")
        return
      }

      this.addedStores = this.checkedStores;

      const storesData = await Promise.all(this.checkedStores.map(async (store) => {
        const response = await axios.get(`${this.$data.apiUrl}/groups/${store}`);
        return response.data[0].profile.name.substring(13);
      }));  

      this.magasinCode = storesData.join('-'); //Map all stores codes shortened with a '-' between each store
 let dateSaisie = document.querySelector('#endOfContract')?.value || '';
console.log("dateSaisie avant regex", dateSaisie);

// Vérifie si la date est au format YYYY-MM-DD
const regexISO = /^\d{4}-\d{2}-\d{2}$/;
// Vérifie si la date est au format DD/MM/YYYY
const regexFR = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

if (regexISO.test(dateSaisie)) {
    // La date est déjà au bon format
    console.log("dateSaisie au format ISO");
} else if (regexFR.test(dateSaisie)) {
    // Conversion du format DD/MM/YYYY vers YYYY-MM-DD
    const [jour, mois, annee] = dateSaisie.split('/');
    const dateObj = new Date(`${annee}-${mois}-${jour}`);
    if (!isNaN(dateObj)) {
        dateSaisie = `${annee}-${mois.padStart(2, '0')}-${jour.padStart(2, '0')}`;
    } else {
        dateSaisie = ''; // Date invalide
    }
} else {
    // Format non reconnu, on vide la variable
    dateSaisie = '';
}

console.log("dateSaisie après regex", dateSaisie);

      if (this.phoneProblem == true || this.secondEmailProblem == true ||  this.firstNameProblem == true || this.lastNameProblem == true || !this.checkedStores.length) { // If there is a problem, stop right there
      } else { // Else if no problem, request is a go
        try {
          var myBody = {
            profile: {
              firstName: (document.querySelector('#firstName').value.substring(0, 1).toUpperCase() + document.querySelector('#firstName').value.substring(1)),
              lastName: (document.querySelector('#lastName').value.substring(0, 1).toUpperCase() + document.querySelector('#lastName').value.substring(1)),
              mobilePhone: document.querySelector('#mobilePhone').value,
              endOfContract: dateSaisie,
              magasinCode: this.magasinCode,
              refPortalRole: (this.isReferent === true && this.profile.refPortalRole === 'Associé(e)') ? "Associé(e)" : ((this.isReferent === true) ? "Responsable" : "Collaborateur"),
              fonction: (this.isReferent === true && this.profile.refPortalRole === 'Associé(e)') ? "associe" : ((this.isReferent === true) ? "responsable" : "collaborateur"),
            },
            apps: assignedApps,
            removedApps: this.removedApps,
            isReferent: this.isReferent,
            stores: this.addedStores,
            removedStores: this.removedStores,
          };

          const response = await axios.post(
            `${this.$data.apiUrl}/users/${userId}`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${this.$data.accessToken}`,
              },
              data: myBody
            }
          );
          this.message = response.data;
          setTimeout(() => {this.$router.push('/')}, 1800);
        } catch (e) {
          this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
          console.log(e);
        }        
      }
    },
    getUserAttributeNames(nameIn) {
      var myNames = { 'firstName': 'Prénom', 'lastName': 'Nom', 'mobilePhone': 'Mobile', 'secondEmail': 'Email secondaire', 'login': 'Login', 'email': 'Email', 'endOfContract': 'Fin de contrat', 'refPortalRole': 'Rôle', 'enseigne': 'Enseigne(s)' };

      var nameOut = myNames[nameIn] ? myNames[nameIn] : nameIn;
      return nameOut;
    },
    getUserStatus(statusIn) {
      var myNames = { 'ACTIVE': 'Actif', 'PROVISIONED': 'En attente d\'activation', 'RECOVERY': 'Password reset', 'DEPROVISIONED': 'Désactivé', 'STAGED': 'Provisionné' };

      var nameOut = myNames[statusIn] ? myNames[statusIn] : statusIn
      return nameOut;
    },
    getFactorsIcon(statusIn) {
      var myNames = { 'ACTIVE': 'fa-solid fa-check', 'NOT_SETUP': 'fa-solid fa-xmark', 'PENDING_ACTIVATION': 'fa-solid fa-question' };

      var nameOut = myNames[statusIn] ? myNames[statusIn] : statusIn
      return nameOut;
    },
    getFactorsIconColor(statusIn) {
      var myNames = { 'ACTIVE': 'green', 'NOT_SETUP': 'red', 'PENDING_ACTIVATION': 'blue' };

      var nameOut = myNames[statusIn] ? myNames[statusIn] : statusIn
      return nameOut;
    },
    getFactorsName(statusIn) {
      var myNames = { 'push': 'Okta Verify Push', 'sms': 'Sms', 'token:software:totp': 'Okta Verify Token', 'token:hardware': 'Token clé de sécurité', 'signed_nonce': 'Okta Verify Nonce' };

      var nameOut = myNames[statusIn] ? myNames[statusIn] : statusIn;
      return nameOut;
    },
    sortApps(apps) {
      return apps.sort((a, b) => { return a.label - b.label }) // Sort apps list by alphabetical order
    },
    getBrand(nameIn) {
      var myBrands = { 'O2K': 'Optic 2000', 'A2K': 'Audio 2000', 'GADOL': 'Gadol', 'LISSAC': 'Lissac' };
      var userBrandsArray = [];

      for (var i = 0; i < nameIn.length; i++) {
        var nameOut = myBrands[nameIn[i]] ? myBrands[nameIn[i]] : nameIn[i];
        userBrandsArray.push(nameOut)
      }

      var userBrands = userBrandsArray.join(', ');


      return userBrands.toString();
    },
    formatDate(value) {
      var date = new Date(value);
      var formatedDate = date.toLocaleString();
      return formatedDate != '01/01/1970 01:00:00' ? formatedDate.substring(0, 10) : 'Aucune'
    }

    
  },
};
</script>

