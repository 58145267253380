<template>
  <router-view />
</template>

<script>
export default {
  name: "app",
  data() {
    return {};
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

::placeholder {
    color: #6c757d !important;
  }

body {
    background: #f9f9f9; 
    /*background: #ffffff;*/
}

input[type="search"]::placeholder {
  color: #6c757d; /* Gris clair pour placeholder */
  font-style: italic; /* Style italique */
  font-size: smaller;
}

input[type="search"]:focus {
  border-color: #007bff; /* Bordure bleue au focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Effet d'ombre */
}

</style>
