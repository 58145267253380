<template>
  <div id="navBar" class="d-flex justify-content-around align-items-center shadow p-3 mb-5 bg-body rounded">
    <img style="height: 6em" alt="Audioptic logo" src="../assets/audioptic_logo.jpeg"/>
    <div class="d-flex flex-column align-items-center">
      <h1 class="h2">Annuaire</h1>
    </div>
    <div id="nav" class="d-flex justify-content-around align-items-center w-75">
      <span class="d-flex justify-content-start" v-if="authState && authState.isAuthenticated">Bonjour, <b>&nbsp; {{ userName }}</b></span>
      <router-link class="btn btn-outline-primary" to="/">Accueil</router-link>      
      <router-link class="btn btn-outline-primary" to="/login" v-if="authState && !authState.isAuthenticated">Connexion</router-link>
      <button class="btn btn-outline-primary" v-if="authState && authState.isAuthenticated" @click="logout()">Déconnexion</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile-okta",
  data() {
    return {
      claims: [],
      userName: {},
      apiUrl: process.env.VUE_APP_API_URL,
      accessToken: localStorage.getItem('accessToken'),
    };
  },
  async created() {
    
    await this.$auth.isAuthenticated().then(async () => {
      this.claims = await this.$auth.getUser();
      this.userName = this.claims.name; // Claims are used to get the username to display in the navbar
      
    })
    .catch(error => console.log(error));
  },
  methods: {
    async logout() {

      axios.defaults.headers.common["Authorization"] = `Bearer ${this.$data.accessToken}`;
      axios.post(`${this.$data.apiUrl}/users/loggedOut`, { loggedOut: this.userName });// Send logout event to logger

      axios.post(`${this.$data.apiUrl}/tokens/revoke`); // Revoke org server Access Token via API call

      this.$auth.revokeAccessToken(); // Revoke default server session's Access Token
      this.$auth.closeSession();
      this.$auth.tokenManager.clear(); // Clear all tokens from tokenManager : ensure no token can be reused      

      localStorage.clear(); // Clear storage so no token or session info remains
      sessionStorage.clear();
    },
  },
};
</script>
