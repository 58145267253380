<template>
  <NavBar class="w-75 mx-auto" />
  <div class="cloneUser w-75 mx-auto">
    <div class="d-flex flex-column">
      <a class="d-flex justify-content-start text-decoration-none" href="/">Retour à la liste des utilisateurs</a>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <h5 style="margin-top: 3rem; text-align: left">Informations à modifier :</h5>
        <div v-if="this.phoneNumberError || this.emailError || this.firstNameError || this.lastNameError">
          <h6 class="text-danger">Erreurs :</h6>
          <p v-if="this.phoneNumberError">{{ this.phoneNumberError }}</p>
          <p v-if="this.emailError">{{ this.emailError }}</p>
          <p v-if="this.lastNameError">{{ this.lastNameError }}</p>
          <p v-if="this.firstNameError">{{ this.firstNameError }}</p>
        </div>     
        <table class="table table-striped mt-2">
          <tbody v-for="(item, label) in profile" :key="(item, label)" v-bind:value="profile.item">
            <tr>
              <td v-if="label != 'login' && label !='refPortalRole' && label !='fonction' && label !='secondEmail' && label != 'magasinCode' && label != 'enseigne'" class="text-start "><strong>{{ getUserAttributeNames(label) }} </strong></td>
              <td v-if="label == 'endOfContract'"><Datepicker v-model="date" model-type="dd/MM/yyyy" locale="fr" id="datepicker" @update:modelValue="handleDate" :format="format" :preview-format="format" :min-date="notToday" ignore-time-validation :enable-time-picker="false" :placeholder="item" :clearable="true"></Datepicker></td>
              <td v-if="label != 'login' && label !='refPortalRole' && label !='fonction' && label !='secondEmail' && label != 'magasinCode' && label != 'enseigne'"><input :name="label" :class="label" :id="label" type="text" :value="item" @input="item = $event.target.value" style="width: 100%" :v-model="label"/></td>
              <td class="text-start" v-if="label == 'login' && label == 'refPortalRole' && label == 'fonction' && label == 'secondEmail' && label == 'magasinCode' && label == 'enseigne'" hidden>{{item}}</td>

              
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="2" class="text-end">
                <div v-if="this.stores.includes('refPortal_superReferents')" class="form-check text-start">
                  <input class="form-check-input" type="checkbox" value="" id="isReferent" v-model="isReferent">
                  <label class="form-check-label" for="flexCheckDefault">Directeur(trice) Magasin(s)</label>
                  <br/>
                  <small>En cochant cette case, vous donnez accès à l’application Annuaire à l’utilisateur pour le(s) code(s) magasin(s) sélectionné(s).</small>
                </div>         
                <button class="btn btn-outline-primary mb-2 mt-3" @click="createUser">Sauvegarder</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>     
      <div class="col-4 ms-3 mt-2">
        <h5 style="margin-top: 3rem">Magasin(s)</h5>
        <div v-for="group in groups" :key="group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :value="group.id" :id="group.id" v-model="userStores">
            <label class="form-check-label" :for="group.id">{{ (group.profile.name).substring(13) }}</label>
          </div>
        </div>           
      </div>
    </div>
  </div>  
</template>

<script>
import axios from "axios";
import NavBar from "../components/NavBar.vue";
import { ref } from 'vue'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

window.$ = window.jQuery = require('jquery');

export default {
  name: "cloneUser",
  components: {
    NavBar,
    Datepicker
  },
  setup() {
        const date = ref(new Date());
        //In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        };

        const today = new Date();
        const notToday = new Date(today.setDate(today.getDate() + 1));

        const handleDate = (modelData) => {
          date.value = modelData
        };

        return {
            date,
            format,
            handleDate,
            today,
            notToday
        };
  },
  async created() {
    this.stores = JSON.parse(localStorage.getItem('defaultServerIdToken')).stores
    let getUrlId = window.location.search;
    let userId = getUrlId.slice(12);
    this.id = userId

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$data.accessToken}`;

    try {
      // Get specific user all infos
      const response = await axios.get(
        `${this.$data.apiUrl}/users/${userId}`
      );
      this.userInfos = response.data;
      this.status = this.userInfos[0].status
      this.profile = this.userInfos[0].profile
      //this.groups = this.userInfos[1]
    } catch (e) {
      console.log(e);
    }

    try {
      // Get groups available to logged referent/super-referent
      const response = await axios.get(`${this.$data.apiUrl}/groups`)
      this.groups = response.data
    }catch(e) {
        console.log(e)
    }

    try {
      if(this.profile.enseigne === undefined) {
        this.neitherRoleNorBrand = true;       
      } else if(this.profile.enseigne != undefined) {
        const brands = this.profile.enseigne.join(', ');
        const response = await axios.get(`${this.$data.apiUrl}/resourceSets/unmodifiableApps?role=${this.profile.refPortalRole}&brands=${brands}`);
        this.unmodifiableApps = response.data;
        const response2 = await axios.get(`${this.$data.apiUrl}/resourceSets/availableApps?role=${this.profile.refPortalRole}&brands=${brands}`);
        this.availableApps = response2.data;             
      }      
    } catch(e) {
      console.log(e)
    }

    if(this.profile.enseigne != undefined) {
      this.unmodifiableApps.forEach(app => {
        this.checkedApps.push(app.id);
      });
    }
    
    if(this.profile.refPortalRole == 'Responsable' || this.profile.refPortalRole == 'Associé(e)') { // Is user is a referent or a super referent, precheck referent checkbox
      this.isReferent = true;
    }

    if(this.profile.endOfContract != '') {
      this.date = this.profile.endOfContract;
    }
    
    window.$('input.endOfContract').parent('td').css('display', 'none'); // Css trick to make disappear an unwanted input on display

  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      accessToken: localStorage.getItem('accessToken'),
      userInfos: [],
      groups: [],
      availableApps: [],
      userApps: [],
      checkedApps: [],
      userStores: [],
      status: {},
      profile: {},
      message: {},
      error: {},
      isReferent: Boolean,
      phoneNumberError: '',
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      phoneProblem: false,
      emailProblem: false,
      firstNameProblem: false,
      lastNameProblem: false,
      unmodifiableApps: []
    };
  },
  methods: {
    async createUser() {
      const phoneRegex = /^(?:(?:(?:\+|00)33\D?(?:\D?\(0\)\D?)?)|0){1}[1-9]{1}(?:\D?\d{2}){4}$/gm;
      const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      const namesRegex = /^(?=.{2,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/gm;
      
      if (document.querySelector('#mobilePhone').value != '') { // Regex validation of phone number
        if(!document.querySelector('#mobilePhone').value.match(phoneRegex)) {
          this.phoneNumberError = `Numéro de téléphone invalide`;
          this.phoneProblem = true
        } else {
          this.phoneProblem = false
        }
      }
      
      if(document.querySelector('#email').value != '') { // Regex validation of email
        if(!document.querySelector('#email').value.match(emailRegex)) {
          this.emailError = `Email invalide`;
          this.emailProblem = true;
        } else {
          this.emailProblem = false
        }
      }

      if(document.querySelector('#firstName').value != '') { // Regex validation of phone number
        if(!document.querySelector('#firstName').value.match(namesRegex)) {
          this.firstNameError = `Prénom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
          this.firstNameProblem = true;
        } else {
          this.firstNameProblem = false
        }
      }

      if(document.querySelector('#lastName').value != '') { // Regex validation of lastName
        if(!document.querySelector('#lastName').value.match(namesRegex)) {
          this.lastNameError = `Nom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
          this.lastNameProblem = true;
        } else {
          this.lastNameProblem = false
        }
      }

      if (document.querySelector('#email').value == '') {
          this.emailError = 'Merci de saisir un email';
          this.emailProblem = true;
        }

      if (document.querySelector('#firstName').value == '') {
          this.firstNameError = 'Merci de saisir un nom de famille';
          this.firstNameProblem = true;
        }

        if (document.querySelector('#lastName').value == '') {
          this.lastNameError = 'Merci de saisir un prénom';
          this.lastNameProblem = true;
        }

      if(!this.userStores.length) {
        alert('Au moins un magasin doit être sélectionné')
      }

      if (this.phoneProblem == true || this.emailProblem == true || this.secondEmailProblem == true || this.firstNameProblem == true || this.lastNameProblem == true || !this.userStores.length ) { // If there is a problem, stop right there
        return
      } else { // Else if no problem, request is a go

        const storesData = await Promise.all(this.userStores.map(async (store) => {
          const response = await axios.get(`${this.$data.apiUrl}/groups/${store}`);
          return (response.data[0].profile.name).substring(13);
        }));
  
        this.magasinCode = storesData.join('-');

        let dateSaisie =  this.date
console.log("dateSaisie avant regex", dateSaisie);

// Vérifie si la date est au format YYYY-MM-DD
const regexISO = /^\d{4}-\d{2}-\d{2}$/;
// Vérifie si la date est au format DD/MM/YYYY
const regexFR = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

if (regexISO.test(dateSaisie)) {
    // La date est déjà au bon format
    console.log("dateSaisie au format ISO");
} else if (regexFR.test(dateSaisie)) {
    // Conversion du format DD/MM/YYYY vers YYYY-MM-DD
    const [jour, mois, annee] = dateSaisie.split('/');
    const dateObj = new Date(`${annee}-${mois}-${jour}`);
    if (!isNaN(dateObj)) {
        dateSaisie = `${annee}-${mois.padStart(2, '0')}-${jour.padStart(2, '0')}`;
    } else {
        dateSaisie = ''; // Date invalide
    }
} else {
    // Format non reconnu, on vide la variable
    dateSaisie = '';
}

console.log("dateSaisie après regex", dateSaisie);

        var enseigne = this.profile.enseigne
        console.log(enseigne);

        var myBody = {
          profile: {  
            firstName: (document.querySelector('#firstName').value.substring(0, 1).toUpperCase() + document.querySelector('#firstName').value.substring(1)),
            lastName: (document.querySelector('#lastName').value.substring(0, 1).toUpperCase() + document.querySelector('#lastName').value.substring(1)),
            mobilePhone: document.querySelector('#mobilePhone').value,
            email: document.querySelector('#email').value,
            login: document.querySelector('#email').value,
            endOfContract: dateSaisie,
            magasinCode: this.magasinCode,
            refPortalRole: (this.isReferent === true) ? "Responsable" : "Collaborateur",
            fonction: (this.isReferent === true) ? "responsable" : "collaborateur",
          },
          stores: this.userStores,
          apps: this.checkedApps,
          isReferent: this.isReferent
        };
        try {
          // create new user
          const response = await axios.post(`${this.$data.apiUrl}/users`, {data: myBody});
          this.message = response.data;
        } catch (e) {
          this.error = `Erreur, l'action demandée n'a pu aboutir, vérifier les logs pour plus d'informations - ${e}`;
        }
        setTimeout(() => {this.$router.push("/")}, 1800);
      }            
    },
    getUserAttributeNames(nameIn) {
      var myNames = {'firstName': 'Prénom', 'lastName': 'Nom', 'secondEmail': 'Email secondaire', 'mobilePhone': 'Mobile', 'email': 'Email', 'endOfContract': 'Fin de contrat', 'enseigne' : 'Enseigne'};        
      var nameOut = myNames[nameIn] ? myNames[nameIn] : nameIn;
      return nameOut;
    },
    sortApps(apps) {
      return apps.sort((a,b) => { return a.label - b.label }); // Sort apps list by alphabetical order
    },
    getBrand(nameIn) {
      var myBrands = { 'O2K': 'Optic 2000', 'A2K': 'Audio 2000', 'GADOL': 'Gadol', 'LISSAC': 'Lissac' };
      var userBrandsArray = [];

      for (var i = 0; i < nameIn.length; i++) {
        var nameOut = myBrands[nameIn[i]] ? myBrands[nameIn[i]] : nameIn[i];
        userBrandsArray.push(nameOut)
      }

      var userBrands = userBrandsArray.join(', ');


      return userBrands.toString();
    },
  },
};
</script>
