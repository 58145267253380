import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Okta SDKs
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";

// Bootstrap v5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// OIDC configuration
import sampleConfig from "@/config";

// Font-awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faCheck, faCircleXmark, faCopy, faHourglassHalf, faLock, faPlus, faQuestion, faToggleOff, faToggleOn, faUnlockKeyhole, faUserAlt, faUserGear, faUserPlus, faUsersGear, faUserShield, faUserTie, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// ✅ Import des fonctions de chargement
import { loadConfig, loadTranslations } from "./services/configService";

// Fonction pour initialiser l'application après le chargement des JSON
async function initApp() {
  const config = await loadConfig();
  const translations = await loadTranslations();

  console.log("✅ Config chargée:", config);
  console.log("✅ Traductions chargées:", translations);

  // Ajout des icônes FontAwesome
  library.add(faUserAlt, faLock, faCheck, faUserTie, faCopy, faQuestion, faXmark, faPenToSquare, faUnlockKeyhole, faCircleXmark, faUserGear, faUsersGear, faUserPlus, faUserTie, faUserShield, faToggleOff, faToggleOn, faArrowRight, faPlus, faHourglassHalf);

  const oktaAuth = new OktaAuth(sampleConfig.oidc);

  // Création de l'application Vue
  const app = createApp(App);

  // Injection des configurations globales
  app.config.globalProperties.$champConfig = config;
  app.config.globalProperties.$translations = translations;

  app
    .use(router)
    .use(OktaVue, {
      oktaAuth,
      onAuthRequired: () => {
        router.push("/login");
      },
      onAuthResume: () => {
        router.push("/login");
      },
    })
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Datepicker', Datepicker)
    .mount("#app");
}

// 🔥 Lancer l'initialisation
initApp();
