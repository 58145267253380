export default {
  oidc: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    issuer: `https://${process.env.VUE_APP_OKTA_DOMAIN}.com`,
    //issuer: `https://sso-preview.audioptic.fr`,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    scopes: ["openid", "profile", "email", "offline_access", "okta.users.read", "okta.groups.read", "okta.factors.read", "okta.apps.read", "okta.users.manage", "okta.users.manage.self", "okta.groups.manage", "okta.factors.manage", "okta.apps.manage", ],
    pkce: true,
    useInteractionCodeFlow: true,
  },
};
