<template>
  <NavBar class="w-75 mx-auto" />
  <div class="userManagement w-75 mx-auto">
    <div class="d-flex flex-column">
      <a class="d-flex justify-content-start text-decoration-none" href="/">Retour à la liste des utilisateurs</a>
    </div>
    <div class="row mt-3 justify-content-center">
      <div class="col-6">
        <h5>Nouvel utilisateur :</h5>
        <div v-if="this.dateError">          
          <p v-if="this.dateError">{{ this.dateError }}</p>
        </div>
        <div v-if="this.emailError || this.firstNameError || this.lastNameError">
          <h6 class="text-danger">Erreurs :</h6>
          <p v-if="this.emailError">{{ this.emailError }}</p>
          <p v-if="this.lastNameError">{{ this.lastNameError }}</p>
          <p v-if="this.firstNameError">{{ this.firstNameError }}</p>
        </div>  
        <table class="table table-striped">
          <tbody>
            <tr>
              <td class="text-start"><label for="email">Email</label></td>
              <td>
                <input id="email"  type="email" style="width: 100%" />
                <!-- <span v-if="v$.email.$error"> {{ v$.email.$errors[0].$message }} </span> -->
              </td>
            </tr>
            <tr>
              <td class="text-start"><label for="firstName">Prénom</label></td>
              <td>
                <input id="firstName"  type="text" style="width: 100%" />
                <!-- <span v-if="v$.firstName.$error"> {{ v$.firstName.$errors[0].$message }} </span> -->
              </td>
            </tr>
            <tr>
              <td class="text-start"><label for="lastName">Nom</label></td>
              <td>
                <input id="lastName"  type="text" style="width: 100%" />
                <!-- <span v-if="v$.lastName.$error"> {{ v$.lastName.$errors[0].$message }} </span> -->
              </td>
            </tr>
            <tr>
              <td class="text-start"><label for="endOfContract">Fin de contrat</label></td>
              <td>
                <Datepicker v-model="date" model-type="dd/MM/yyyy" :clearable="true" locale="fr" :format="format" :preview-format="format"  :min-date="notToday" ignore-time-validation :enable-time-picker="false"></Datepicker>
              </td>
            </tr>                 
          </tbody>
        </table>
        <table>          
          <tbody>
            <tr>
              <td class="col-12">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="isReferent">
                  <label class="form-check-label" for="flexCheckDefault">Directeur(trice) Magasin(s)</label>
                  <br/>
                  <small>En cochant cette case, vous donnez accès à l’application Annuaire à l’utilisateur pour le(s) code(s) magasin(s) sélectionné(s).</small>                  
                </div>
              </td>
              <td class="col-12 text-end mt-3">
                <button class="btn btn-outline-primary mb-2 mt-5" @click="createUser">Sauvegarder</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-4 ms-3 mt-2">
        <h5>Magasin(s)</h5>
        <div v-for="group in groups" :key="group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :value="group.id" :id="group.id" v-model="checkedStores">
            <label class="form-check-label" :for="group.id">{{ (group.profile.name).substring(13) }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from 'vue'
import NavBar from "../components/NavBar.vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default {
  name: "userCreate",
  components: {
    NavBar,
    Datepicker
  },
  setup() {
        const date = ref(null);
        //In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        const today = new Date();
        const notToday = new Date(today.setDate(today.getDate() + 1));

             return {
            date,
            format,
            today,
            notToday
        }
  },
  async created() {    
    
    this.stores = JSON.parse(localStorage.getItem('defaultServerIdToken')).stores
    
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$data.accessToken}`;

    try {
      // Get groups available to logged referent/super-referent
      const response = await axios.get(`${this.$data.apiUrl}/groups`)
      this.groups = response.data
    }catch(e) {
        console.log(e)
    }


  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      accessToken: localStorage.getItem('accessToken'),
      groups: [],
      availableApps: [],
      checkedApps: [],
      checkedStores: [],
      storesCode: [],
      magasinCode: '',
      message: {},
      error: {},
      isReferent: Boolean,
      dateError: '',
      dateProblem: false,
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      emailProblem: false,
      firstNameProblem: false,
      lastNameProblem: false
    };
  },
  methods: {
    async createUser() {

      const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
      const namesRegex = /^(?=.{2,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/g;

      if (document.querySelector('#email').value != '') { // Regex validation of phone number
          if (!document.querySelector('#email').value.match(emailRegex)) {
            this.emailError = `Email invalide`;
            this.emailProblem = true;
          } else {
            this.emailProblem = false;
          }
        }

        if (document.querySelector('#email').value == '') {
          this.emailError = 'Merci de saisir un email';
          this.emailProblem = true;
        }

        if (document.querySelector('#firstName').value != '') { // Regex validation of firstName
          if (!document.querySelector('#firstName').value.match(namesRegex)) {
            this.firstNameError = `Prénom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
            this.firstNameProblem = true;
          } else {
            this.firstNameProblem = false;
          }
        }

        if (document.querySelector('#firstName').value == '') {
          this.firstNameError = 'Merci de saisir un nom de famille';
          this.firstNameProblem = true;
        }

        if (document.querySelector('#lastName').value == '') {
          this.lastNameError = 'Merci de saisir un prénom';
          this.lastNameProblem = true;
        }

        if (document.querySelector('#lastName').value != '') { // Regex validation of lastName
          if (!document.querySelector('#lastName').value.match(namesRegex)) {
            this.lastNameError = `Nom invalide : Accents interdits, seuls les ', - ou espaces sont autorisés`;
            this.lastNameProblem = true;
          } else {
            this.lastNameProblem = false;
          }
        }

      if (!this.checkedStores.length) { // Check if at least one store is selected
        alert('Sélectionner au moins un magasin')
      } else if(this.dateProblem == true || this.emailProblem == true || this.lastNameProblem == true || this.firstNameProblem == true || !this.checkedStores.length) { // Stop if date problem
        return
      } else {

          const storesData = await Promise.all(this.checkedStores.map(async (store) => {
            const response = await axios.get(`${this.$data.apiUrl}/groups/${store}`);
            return (response.data[0].profile.name).substring(13);
          }));
    
          this.magasinCode = storesData.join('-');

            if (!this.date || !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(this.date)) 
            {
            this.date = '';
            } else 
            {
            const [jour, mois, annee] = this.date.split('/');
            const dateObj = new Date(`${annee}-${mois}-${jour}`);
            if (dateObj instanceof Date && !isNaN(dateObj)) {
                    this.date = `${annee}-${mois.padStart(2, '0')}-${jour.padStart(2, '0')}`;
                } else {
                    this.date = '';
                }
             }

            var myBody = {
              profile: {
                firstName: (document.querySelector('#firstName').value.substring(0, 1).toUpperCase() + document.querySelector('#firstName').value.substring(1)),
                lastName: (document.querySelector('#lastName').value.substring(0, 1).toUpperCase() + document.querySelector('#lastName').value.substring(1)),
                email: document.querySelector("#email").value,
                login: document.querySelector("#email").value,
                magasinCode: this.magasinCode,
                endOfContract: this.date ? this.date : '',
                refPortalRole: (this.isReferent === true) ? "Responsable" : "Collaborateur",
                fonction: (this.isReferent === true) ? "responsable" : "collaborateur",
              },
              stores: this.checkedStores,
              apps: this.checkedApps,
              isReferent: this.isReferent
            };

            try {

              // create new user
              const response = await axios.post(`${this.$data.apiUrl}/users`, {data: myBody});
              if(response.data.status == 'PROVISIONED') {
                //Ok
              }
            } catch (error) {
              this.error = JSON.stringify(error.response.data);
              alert(this.error);
              return;
            }
            setTimeout(() => {this.$router.push("/")}, 1800);
            }
          }                           
    },  
    getUserAttributeNames(nameIn) {
      var myNames = {'firstName': 'Prénom', 'lastName': 'Nom', 'mobilePhone': 'Mobile', 'secondEmail': 'Email secondaire', 'endOfContract': 'Fin de contrat'};        
      var nameOut = myNames[nameIn] ? myNames[nameIn] : nameIn;
      return nameOut;
    },
    sortApps(apps) {
      return apps.sort((a,b) => { return a.label - b.label }); // Sort apps list by alphabetical order
    },
  };

</script>
